<script>
export default {
  name: 'EmailModule'
}
</script>

<template>
  <div>
    <RouterView />
  </div>
</template>
